import React, { createContext, useContext, useState,useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import {useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useTranslation } from 'react-i18next';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../B2C';
import { useMsal, useAccount } from '@azure/msal-react';
import {checkPassword} from '../components/tssUtils/tssValidator.js';

const Context = createContext();

export const StateContext = ({ children }) => {
  const { t }  = useTranslation(['page']);
  const location = useLocation();
    // const [cartItems, setCartItems] = useState([]); example
    const [userEmail,setUserEmail] = useState(null);
    const [userName,setUserName] = useState(null);
    const [checked,setChecked] = useState(false);
    const [userIsAgree, setUserIsAgree] = useState(false);
    const [userIsAgreeDisplay, setUserIsAgreeDisplay] = useState(false);
    const [isDisplayInfo, setIsDisplayInfo] = useState(false);
    const [modiStatus,setModiStatus] = useState(false);
    const [userEnterpriseID,setUserEnterpriseID] = useState(null);
    const [useraccessToken, setUseraccessToken] = useState(null);
    const [userAuthority, setUserAuthority] = useState(null);
    const [selectEnID, setSelectEnID] = useState(null);
    const [selectEnName, setSelectEnName] = useState(null);
    const [step, setStep] = useState(null);
    const [stepData, setStepData] = useState(null);
    const [checkLangChang, setCheckLangChang] = useState(false);
    const [langChangCrUser, setLangChangCrUser] = useState(false);
    const [errorChk, setErrorChk] = useState(null);
    const [listData, setListData] = useState([]);
    const [calendarendCheck, setCalendarEndCheck] = useState(false);
    // const [selGroup, setSelGroup]= useState([]); //LinearStepper Step= 0 select ProjectGroup (2023/03/22)
    const [EnterpriseList,setEnterpriseList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [goAdminHome, setGoAdminHome] = useState(false);
    const [goHome, setGoHome] = useState(false);
    const [fitFlag, setFitFlag] = useState(null);
    const [interviewBARSFlag, setInterviewBARSFlag] = useState(null);
    const [trialFlag, setTrialFlag] = useState(null);
    const [noticeFlag, setNoticeFlag] = useState(null);
    //loading
    const [loading, setLoading] = useState(null);
    // handle error
    const [error, setError] = useState(false);
    const { instance, accounts,  inProgress} = useMsal();
    const account = useAccount(accounts[0] || {});
 
    const headers = new Headers();
    const bearer = `Bearer ${localStorage.getItem('accessToken')}`;
    headers.append('authorization', bearer);
    
    let options = {
      method: 'POST',
      headers: headers,
      body: ''
    };
   
    const navigate = useNavigate();

    const fetchWithTimeoutImpl = (url, options, timeout) => {
      const controller = new AbortController();
      const { signal } = controller;
  
      const timeoutId = setTimeout(() => {
          controller.abort();
      }, timeout);
  
      return fetch(url, { ...options, signal })
        .finally(() => {
          clearTimeout(timeoutId);
        });
    }
  
    const fetchWithTimeout = (url, options, timeout) => {
      return new Promise((resolve, reject) => {
          const onFailedNine = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(reject);
          }
          const onFailedEight = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedNine);
          }
          const onFailedSeven = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedEight);
          }
          const onFailedSix = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedSeven);
          }
          const onFailedFive = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedSix);
          }
          const onFailedFour = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedFive);
          }
          const onFailedThird = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedFour);
          }
          const onFailedSecond = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedThird);
          }
          const onFailedFirst = () => {
            fetchWithTimeoutImpl(url, options, timeout)
                  .then(resolve)
                  .catch(onFailedSecond);
          }
          fetchWithTimeoutImpl(url, options, timeout)
            .then(resolve)
            .catch(onFailedFirst);
      });
    }

    useEffect(() => {
      if (account && inProgress === 'none') {
        instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
        localStorage.setItem('accessToken', response.accessToken);
  
        setUseraccessToken(response.accessToken);
        setUserEmail(account.idTokenClaims.emails[0]);
        // setUserName(account.idTokenClaims.family_name + ' ' +account.idTokenClaims.given_name );
        setUserEnterpriseID(account.idTokenClaims.extension_enterpriseID);

      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === 'none') {
              instance.acquireTokenPopup({
                  scopes: protectedResources.apiHello.scopes,
              }).then((response) => {
                  console.log('response.accessToken ==> ', response.accessToken);
                  /*callApiWithToken(response.accessToken, protectedResources.apiHello.endpoint)
                      .then(response => setHelloData(response));*/
              }).catch(error => console.log(error));
            }
        }
      });
      }
    }, [account, inProgress, instance]);

    useEffect(()=>{
      useraccessToken && selectUserAuth(useraccessToken);
      useraccessToken && selectUserPassword(useraccessToken);
    },[useraccessToken]);
    
    const selectUserAuth=(userToken)=>{
      setLoading(true)
      const headers = new Headers();
      const bearer = `Bearer ${userToken}`;

      headers.append('authorization', bearer);
  
      let options = {
        method: 'POST',
        headers: headers,
        body: ''
      };
    

      const url= `${process.env.REACT_APP_API_URL}/selectUserAuth `;
      options.body = JSON.stringify({userToken: userToken});
  
//      fetch(url, options).then((response) => {
      fetchWithTimeout(url, options, 4096).then((response) => {
        if(!response.ok) {
          if(response.status==401){
            setErrorChk(true);
          }else{
  
            throw Error('Could not fetch the data for that resource');
          }
        }
        return response.json();
      }).then((data) => { 
        setUserAuthority(data[0].userAuthority);
        setUserName(data[0].surname+' '+data[0].givenName);
        setTrialFlag(data[0].TrialVersionFlag);
        setNoticeFlag(data[0].NoticeFlag);
        if(data[0].userAuthority !=0){
          setSelectEnID(data[0].enterpriseID);
        }
        setLoading(false)
      }).catch(error => {
        console.log(error.message);
        setLoading(false)
        navigate('/error');
      });
    }

    const redirectLogout =()=>{
      instance.logoutPopup({postLogoutRedirectUri: '/', mainWindowRedirectUri: '/'})
    };

    useEffect(()=>{
      if(userEmail == undefined || userEmail == '' || userEmail == null) {
        setUserEmail(account.idTokenClaims.emails[0])
      }
    },[]);
  
    const sameData =(data)=>{
      let value ;
      if(step == 'CreateProjectGroup'){     
        for(let i= 0; i<listData.length; i++){

          if(listData[i].GroupID == data.GroupID){
            value= false;
            break;
          }else if(listData[i].GroupName == data.GroupName){       
            value= true;
            break;
          }
        }
        return value;
      }else if(step == 'CreateUser'){
        for(let i= 0; i<listData.length; i++){
          if(listData[i].signInNames == data){
            return true;
          }
        }
        return false;
      }else if(step == 'CreateEnterprise'){
        for(let i=0; i<listData.length; i++){
          if(listData[i].EnterpriseID == data){
            return true;
          }
        }
        return false;
      }else if(step == 'CreateNotification'){
        for(let i=0; i<listData.length; i++){
          if(listData[i].NoticeID == data){
            return true;
          }
        }
        return false;
      }
    }

    const alertWarning = (text) => {
      Swal.fire({ 
        html: text,     
        icon: 'warning',
        confirmButtonColor: '#2962ff',
        confirmButtonText: t(`page:alertPopUP.confirmBtnText_Yes`),
      })
    }

    useEffect(()=>{
      if(userAuthority!= null && !error){
        if(userAuthority!=2&& (location.pathname === '/home' || location.pathname === '/ManageProjectGroup'||  location.pathname === '/ManageUser') ){
          // setListData([]);
          options.body = JSON.stringify({
            Email: userEmail
          });
          
          const url= `${process.env.REACT_APP_API_URL}/selectEnterpriseAll`;
          fetchWithTimeout(url, options, 2048).then((response) => {
            if(!response.ok) {
              throw Error(response.errMsg);
            }
            return response.json();
          }).then((data) => {
            if(userAuthority == 0 ){
              data.map((item) => { item.value = `${item.SearchKeyword} ${item.EnterpriseNameKANA}`; item.label = item.EnterpriseName; });
              setEnterpriseList(data);
            }else if(userAuthority == 1){
              setSelectEnID(data[0].EnterpriseID);
            }
          }).catch(error => {
            setErrorChk(500);
            setError(true);
            console.log(error.message);
          });
          if(location.pathname == '/ManageUser') modiStatus && setModiStatus(false);
        }       
      }

    },[userAuthority,location.pathname, error]);
    useEffect(()=>{
      //
      //if(userEmail && useraccessToken){
        
        // options.body = JSON.stringify({Email: userEmail});
        // const url= `${process.env.REACT_APP_API_URL}/selectUserPassword`;
    
        // fetch(url, options).then((response) => {
        //   if(!response.ok) {
        //     throw Error(response.errMsg);
        //   }
        //   return response.json();
        // }).then((data) => {
        //   if(data[0]?.PasswordUpdateDateTime === 'new') {
        //     !isDisplayInfo && setIsDisplayInfo(true);
        //     if(!userIsAgree) {
        //       setUserIsAgreeDisplay(true)
        //     } else {
        //       !showModal && setShowModal(true);
        //     }
        //   }
        // }).catch(error => {
        //   setError(true);
        //   console.log(error.message);
        // });
        //selectUserPassword(useraccessToken);
      //}
    },[userEmail,useraccessToken,showModal]);

    const selectUserPassword=(userToken)=>{
      const headers = new Headers();
      const bearer = `Bearer ${userToken}`;

      headers.append('authorization', bearer);
  
      let options = {
        method: 'POST',
        headers: headers,
        body: ''
      };

      options.body = JSON.stringify({Email: userToken});
        const url= `${process.env.REACT_APP_API_URL}/selectUserPassword`;
     
//      fetch(url, options).then((response) => {
        fetchWithTimeout(url, options, 4096).then((response) => {
          if(!response.ok) {
            throw Error(response.errMsg);
          }
          return response.json();
        }).then((data) => {
          if(data[0]?.PasswordUpdateDateTime === 'new') {
            !isDisplayInfo && setIsDisplayInfo(true);
            if(!userIsAgree) {
              setUserIsAgreeDisplay(true)
            } else {
              !showModal && setShowModal(true);
            }
          }
        }).catch(error => {
          setError(true);
          console.log(error.message);
        });
    }

    const gotoAdminHome = () => {
      if(location.pathname === '/home' ||  location.pathname === '/ManageEnterprise' ||location.pathname === '/ManageProjectGroup'||  location.pathname === '/ManageUser'||  location.pathname === '/ManageNotification'){
        navigate('/AdminHome');
      }else if(checked){
        Swal.fire({
          html: t(`page:alertPopUP.back_button`),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2962ff',
          cancelButtonColor: '#2962ff',
          confirmButtonText: t(`page:alertPopUP.confirmBtnText_Yes`),
          cancelButtonText:  t(`page:alertPopUP.confirmBtnText_No`)
        }).then((result) => {
          if (result.isConfirmed) {          
            setGoAdminHome(true);               
          }else{
            navigate('/AdminHome');
            checked && setChecked(!checked);
            setGoAdminHome(false);            
          }
        })
      }else{
        navigate('/AdminHome');
      }
      return <button onClick={gotoAdminHome} />
    }
    const gotoHome = () => {
      if(location.pathname === '/home' ||  location.pathname === '/ManageEnterprise' ||location.pathname === '/ManageProjectGroup'||  location.pathname === '/ManageUser'||  location.pathname === '/ManageNotification'){
        navigate('/home');
      }else if(checked){
        Swal.fire({
          html: t(`page:alertPopUP.back_button`), 
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2962ff',
          cancelButtonColor: '#2962ff',
          confirmButtonText: t(`page:alertPopUP.confirmBtnText_Yes`),
          cancelButtonText:  t(`page:alertPopUP.confirmBtnText_No`)
        }).then((result) => {
          if (result.isConfirmed) {          
            setGoHome(true);               
          }else{
            navigate('/home');
            checked && setChecked(!checked);
            setGoHome(false);            
          }
        })
      }else{
        navigate('/home');
      }
      return <button onClick={gotoHome} />
    }
   
    const closeShowModal = () => {
      setErrMsg('');
      setShowModal(false);
    }
  
    const updatePswd = (pswd1, pswd2) => {
      if(pswd1 !== pswd2) {
        setErrMsg('パスワードが一致していません');
        return;
      }

      if (!checkPassword(pswd1)) {
        setErrMsg(t(`page:createUser.passwordCheck`));
        return;
      }
      setLoading(true);
      options.body = JSON.stringify({email: userEmail, pswd: pswd1});
      let url= process.env.REACT_APP_API_CHNGPSWD;
  
      fetch(url, options).then((response) => {
        if(!response.ok) {
          throw Error(response.errMsg);
        }
      }).then(() => {
        options.body = JSON.stringify({Email: userEmail});
        url= `${process.env.REACT_APP_API_URL}/updateUserPassword`;
    
        fetch(url, options).then((response) => {
          if(!response.ok) {
            throw Error('Could not fetch the data for that resource');
          }
        }).then(() => { 
          closeShowModal();
          setLoading(false);
          isDisplayInfo && setIsDisplayInfo(false)
        }).catch(error => {
          setError(true);
          console.log(error.message);
        }); 
  
      }).catch(error => {
        setError(true);
        console.log(error.message);
      });
    }
    return (
      <Context.Provider
        value={{
            userEmail,setUserEmail,
            userName,setUserName,
            checked,setChecked,
            userEnterpriseID,setUserEnterpriseID,
            useraccessToken, setUseraccessToken,
            userAuthority, setUserAuthority,
            selectEnID, setSelectEnID,
            selectEnName, setSelectEnName,
            step, setStep,
            stepData, setStepData,
            checkLangChang, setCheckLangChang,
            errorChk, setErrorChk,
            listData, setListData,
            alertWarning,
            sameData,
            calendarendCheck, setCalendarEndCheck,
            // selGroup,setSelGroup,
            EnterpriseList,
            loading, setLoading,
            error, setError,
            langChangCrUser, setLangChangCrUser,
            showModal, setShowModal,updatePswd,errMsg,closeShowModal,
            redirectLogout,
            gotoAdminHome,goAdminHome, setGoAdminHome,
            gotoHome,goHome, setGoHome,
            fitFlag, setFitFlag,
            interviewBARSFlag, setInterviewBARSFlag,
            modiStatus, setModiStatus,
            userIsAgree, setUserIsAgree,
            isDisplayInfo,
            userIsAgreeDisplay, setUserIsAgreeDisplay,
            trialFlag,setTrialFlag,
            noticeFlag,setNoticeFlag
        }}
      >
        {children}
      </Context.Provider>
    )
  }

  export const useStateContext = () => useContext(Context);